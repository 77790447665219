import React from "react";
import TranscriptEditor from "./TranscriptEditor";

const Index = React.memo(
  ({
    caseId,
    mediaUrl,
    data,
    setData,
    meetingId,
    transcriptId,
    title,
    updateState,
    meetingAudioId,
    render,
    setRender,
    captureText,
    setFilterSpeaker,
    LiveTranscription,
  }) => {
    let newArray = data.map((item) => item.speaker);

    const filteredArray = newArray.filter((item) => {
      if (typeof item === "string") {
        // Check if the string starts with a specific prefix
        if (item.startsWith("Speaker")) {
          return false; // Exclude this element from the result
        }
      }
      return true; // Include all other elements
    });

    let speakersData = [...new Set(filteredArray)];

    return (
      <React.Fragment>
        <div style={{ paddingTop: "2px" }}>
          <TranscriptEditor
            class="playerButton"
            width="100%"
            mediaUrl={mediaUrl}
            isEditable={true}
            spellCheck={false}
            mediaType={"audio"}
            {...{
              data,
              setData,
              meetingId,
              caseId,
              speakersData,
              transcriptId,
              title,
              updateState,
              meetingAudioId,
              render,
              setRender,
              captureText,
              setFilterSpeaker,
              LiveTranscription,
            }}
          />
        </div>
      </React.Fragment>
    );
  },
);

export default Index;
