import React, { useState, useMemo, useEffect, useCallback } from "react";
import ActionBar from "./ActionBar";
import styled from "styled-components";
import { useLocation, navigate } from "@reach/router";
import _ from "lodash";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { makeStyles, Select } from "@material-ui/core";
import { TextType } from "../../../helpers/constants";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { createEditor, Transforms, Range, Editor } from "slate";
import { withHistory } from "slate-history";
import UpdateSpeaker from "../../transcripts/SlateEditor/UpdateSpeaker";
import { StyledMenuItem } from "../../common/FormInputs";
// import { PauseOutlined, PlayArrow } from "@material-ui/icons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import "./Audio.css";

const CrossExaminationTranscript = (id) => {
  const history = useLocation();
  const resolutionKind = history.search.replace("?caseType=", "");
  const breadcrumbs = [
    "Cases",
    _.startCase(resolutionKind),
    "Cross Examination",
  ];
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const [value, setValue] = useState([]);
  const [state, setState] = useState({ state: false });
  //   const videoRef = useRef(null);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [referesh, setReferesh] = useState(true);
  const [render, setRender] = useState(false);
  const [data, setData] = useState([]);
  const transcriptId = history?.state?.id;
  const [transcriptData, setTranscriptData] = useState([]);
  const status = true;
  const [cursor, setCursor] = useState({});

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case resolutionKind:
        navigate(`/dashboard/cases/${id?.id}?caseType=${resolutionKind}`);
        break;
      case "cases":
        navigate(`/dashboard/cases?caseType=${resolutionKind}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getTranscriptionData = async (transcriptId) => {
      try {
        setLoader({ state: true, message: "Fetching transcript..." });
        const response = await CaseService.getLiveTranscription(transcriptId);
        if (response) {
          setData(
            response?.transcriptData?.map((el) => {
              return {
                speaker: el.speaker ? el?.speaker : "test",
                text: el.text,
                textType: el.textType ? el.textType : "Question",
                mediaId: el?.mediaId,
                questionNo: el?.questionNo,
                audioUrl: el?.audioUrl,
              };
            })
          );
          setRender(true);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    };
    if (referesh) {
      getTranscriptionData(transcriptId);
      setReferesh(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referesh]);

  useEffect(() => {
    if (data) {
      const response = splitContent(data);
      setValue(response);
      setRender(false);
    }
    if (value) {
      editor.history.undos = [];
      editor.children = value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  useEffect(() => {
    if (value) {
      setTranscriptData(
        value.map((el) => {
          return {
            id: el?.id,
            text: el?.children[0].text,
            speaker: el?.speaker,
            mediaId: el?.mediaId,
            audioUrl: el?.audioUrl,
            textType: el?.textType ? el.textType : "Question",
            questionNo: el?.questionNo,
          };
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  let newArray = editor.children.map((item) => item.speaker);

  let speakersData = [...new Set(newArray)];

  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: COLORS.INPUT_GRAY,
    },
    selectInput: {
      color: "#293461", // Change the font color here
      fontFamily: theme.fonts.primaryFontSemiBold, // Change the font family here
    },
  }));

  const generateText = (words) => {
    return words.text;
  };

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const handleChange = (e, element) => {
    const pathToCurrentNode = ReactEditor.findPath(editor, element);
    const value = e.target.value;
    if (value) {
      Transforms.setNodes(
        editor,
        { type: "timedText", textType: value },
        { at: pathToCurrentNode }
      );
    }
  };

  const splitContent = (paragraphs) => {
    return paragraphs.map((paragraph, index) => ({
      speaker: paragraph.speaker,
      id: paragraph.id,
      audioUrl: paragraph.audioUrl,
      textType: paragraph.textType,
      type: "timedText",
      currentTime: paragraph.currentTime,
      duration: paragraph.duration,
      mediaId: paragraph.mediaId,
      questionNo: paragraph.questionNo,
      children: [
        {
          text: generateText(paragraph),
        },
      ],
    }));
  };

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    return (
      <span className={"timecode text"} {...attributes}>
        {children}
      </span>
    );
  }, []);

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "timedText":
        return <TimedTextElement {...props} />;
      default:
        return <DefaultElement {...{ props }} />;
    }
  }, []);

  const DefaultElement = (props) => {
    return <p {...props.attributes}>{props.children}</p>;
  };

  const TimedTextElement = (props) => {
    return (
      <React.Fragment>
        <DrawerContainer style={{ display: "flex" }} contentEditable={false}>
          <Select
            style={{
              height: "32px",
              width: "11%",
              borderRadius: "5px",
              color: "#293461",
              fontFamily: theme.fonts.primaryFontSemiBold,
            }}
            disableUnderline={true}
            onChange={(e) => handleChange(e, props.element)}
            defaultValue={props.element.textType}
            renderValue={
              props.element.textType !== ""
                ? undefined
                : () => <Placeholder>{"Select Type"}</Placeholder>
            }
          >
            {TextType.map((element, index) => (
              <StyledMenuItem
                key={index}
                value={element.value}
                disabled={element.disabled}
              >
                {element.label}
              </StyledMenuItem>
            ))}
          </Select>
          <ValueSpeaker
            onClick={() =>
              setState({
                state: true,
                element: props.element,
              })
            }
          >
            {props.element.speaker}
          </ValueSpeaker>
          <ValueConfidence>{props.element.questionNo}</ValueConfidence>
          <AudioBar>
            {props.element.audioUrl ? (
              <div className="audio-player-container">
                <audio className="audio-player" controls>
                  <source src={props.element.audioUrl} />
                </audio>
              </div>
            ) : null}
            {/* <audio
                id="audioUrlId"
                ref={videoRef}
                src={props.element.audioUrl}
                onLoadedMetadata={(event) =>
                  handleMetaDataTimeUpdate(event, props.element)
                }
                // controls
                // playsInline
                // style={{
                //   display: "inline",
                // }}
              />
              <ButtonPlayer onClick={() => togglePlay(props.element)}>
                {props.element.media ? <PauseOutlined /> : <PlayArrow />}
              </ButtonPlayer>
              <Bar
                type="range"
                min="0"
                style={{
                  accentColor: COLORS.BTN_GREEN,
                  // color: "rgba(80, 151, 255, 0.38)",
                }}
                max={props.element.duration}
                value={props.element.currentTime}
              /> */}
          </AudioBar>
        </DrawerContainer>
        <CustomTextAreaOtter
          style={{
            color: COLORS.COLOR_DARK,
          }}
        >
          {props.children}
        </CustomTextAreaOtter>
      </React.Fragment>
    );
  };

  const updateXExamTranscriptDoc = async (transcriptId) => {
    try {
      setLoader({ state: true, message: "Update transcript..." });
      const payload = {
        editedTranscriptionData: transcriptData,
      };
      const response = await CaseService.updateXExamTranscriptDoc(
        payload,
        transcriptId
      );
      if (response) {
        setReferesh(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const actions = [
    {
      text: "Save (alt+s)",
      props: {
        onClick: () => updateXExamTranscriptDoc(transcriptId),
      },
      outlined: true,
    },
  ];

  const handleOnKeyDown = (event) => {
    if (event.altKey && event.key === "s") {
      updateXExamTranscriptDoc(transcriptId);
    } else if (event.key === "Backspace") {
      const { selection } = editor;
      const { focus } = selection;
      const { offset } = focus;
      if (focus && offset === 0 && focus.path.length > 1) {
        if (selection && Range.isExpanded(selection)) {
          const [start, end] = Range.edges(selection);
          const range = { anchor: start, focus: end };
          Transforms.delete(editor, { at: range });
          const [node, nodePath] = Editor.node(editor, range.anchor.path);
          const maxOffset = node.text.length; // Maximum offset for the node

          // Validate the offset and set it to a valid value if out of bounds
          const offset = Math.min(range.anchor.offset, maxOffset);
          const adjustedSelection = {
            anchor: { path: nodePath, offset },
            focus: { path: nodePath, offset },
          };
          editor.selection = null;
          setCursor({
            selection: adjustedSelection,
          });
        } else {
          const prevPath = focus.path.slice(0, focus.path.length - 1);
          const prevNode = Editor.node(editor, prevPath)[0];
          const addNode = Editor.node(editor, [prevPath - 1])[0];
          Transforms.setNodes(
            editor,
            {
              type: "timedText",
              mediaId: addNode?.mediaId + "," + prevNode?.mediaId,
              audioUrl: addNode?.audioUrl + "," + prevNode?.audioUrl,
            },
            { at: [prevPath - 1] }
          );
        }
      } else {
        if (selection && Range.isExpanded(selection)) {
          const [start, end] = Range.edges(selection);
          const range = { anchor: start, focus: end };
          Transforms.delete(editor, { at: range });
          const [node, nodePath] = Editor.node(editor, range.anchor.path);
          const maxOffset = node.text.length; // Maximum offset for the node

          // Validate the offset and set it to a valid value if out of bounds
          const offset = Math.min(range.anchor.offset, maxOffset);
          const adjustedSelection = {
            anchor: { path: nodePath, offset },
            focus: { path: nodePath, offset },
          };
          editor.selection = null;
          setCursor({
            selection: adjustedSelection,
          });
        }
      }
    } else if (event.key === "Delete") {
      const { selection } = editor;
      const { focus } = selection;
      const { offset } = focus;
      const prevPath = focus.path.slice(0, focus.path.length - 1);
      const prevNode = Editor.node(editor, prevPath)[0];
      if (prevNode.children[0].text.length === offset) {
        if (selection && Range.isExpanded(selection)) {
          const [start, end] = Range.edges(selection);
          const range = { anchor: start, focus: end };
          Transforms.delete(editor, { at: range });
          const [node, nodePath] = Editor.node(editor, range.anchor.path);
          const maxOffset = node.text.length; // Maximum offset for the node

          // Validate the offset and set it to a valid value if out of bounds
          const offset = Math.min(range.anchor.offset, maxOffset);
          const adjustedSelection = {
            anchor: { path: nodePath, offset },
            focus: { path: nodePath, offset },
          };
          editor.selection = null;
          setCursor({
            selection: adjustedSelection,
          });
        } else {
          const addNode = Editor.node(editor, [parseInt(prevPath) + 1])[0];
          Transforms.setNodes(
            editor,
            {
              type: "timedText",
              mediaId: prevNode?.mediaId + "," + addNode?.mediaId,
              audioUrl: prevNode?.audioUrl + "," + addNode?.audioUrl,
            },
            { at: [prevPath] }
          );
        }
      } else {
        if (selection && Range.isExpanded(selection)) {
          const [start, end] = Range.edges(selection);
          const range = { anchor: start, focus: end };
          Transforms.delete(editor, { at: range });
          const [node, nodePath] = Editor.node(editor, range.anchor.path);
          const maxOffset = node.text.length; // Maximum offset for the node

          // Validate the offset and set it to a valid value if out of bounds
          const offset = Math.min(range.anchor.offset, maxOffset);
          const adjustedSelection = {
            anchor: { path: nodePath, offset },
            focus: { path: nodePath, offset },
          };
          editor.selection = null;
          setCursor({
            selection: adjustedSelection,
          });
        }
      }
    } else if (event.key === "Enter") {
      const { selection } = editor;
      if (selection && Range.isExpanded(selection)) {
        event.preventDefault();
        // const prevPath = focus.path.slice(0, focus.path.length - 1);
        // Transforms.setNodes(editor, { at: [prevPath] });
      }
    }
  };

  useEffect(() => {
    if (cursor?.selection) {
      editor.selection = cursor.selection;
      setCursor({});
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <React.Fragment>
        <ActionBar {...{ actions, breadcrumbs, onBreadcrumbClick, status }} />
        <Container>
          <div style={{ paddingTop: "10px" }}>
            <BoxContainer>
              <Main>
                {value.length !== 0 ? (
                  <Slate
                    editor={editor}
                    value={value}
                    updateAudioTranscriptData
                    onChange={(value) => {
                      return setValue(value);
                    }}
                  >
                    <Editable
                      readOnly={false}
                      autoFocus
                      renderElement={renderElement}
                      renderLeaf={renderLeaf}
                      onKeyDown={handleOnKeyDown}
                    />
                  </Slate>
                ) : null}
              </Main>
            </BoxContainer>
          </div>
        </Container>
      </React.Fragment>
      {state?.state ? (
        <UpdateSpeaker
          {...{ state, setState, speakersData, editor }}
          isEditable={true}
        />
      ) : null}
    </>
  );
};

export default CrossExaminationTranscript;

export const Container = styled.div`
  padding: 4px 43px;
  display: flex;
  flex-direction: column;
`;

const Main = styled.section`
  background-color: ${COLORS.PRIMARY_WHITE};
  height: 590px;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: thin;
  border-radius: 10px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  box-shadow: 0 0 5px rgba(80, 151, 255, 0.68);
`;

const DrawerContainer = styled.div`
  width: 100%;
  padding: 10px 23px;
  display: flex;
  align-items: center;
`;

const ValueSpeaker = styled.span`
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  padding-right: 6px;
  padding-left: 10px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

export const StyledDropdownIcon = styled.img`
  height: 4px;
  width: 7px;
  background-color: ${COLORS.INPUT_BACKGROUND};
  position: absolute;
  right: 13px;
`;

const BoxContainer = styled.div`
  background-color: ${COLORS.BORDER_GREY};
  position: relative;
  border-radius: 10px;
`;

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15px;
  margin-bottom: 20px;
  &:nth-child(5n) {
    margin-bottom: 25px;
  }
`;

export const Value = styled.div`
  height: 10px;
  display: block;
  align-items: center;
  margin-bottom: 20px;
`;

const CustomTextAreaOtter = styled.div`
  width: 80%;
  font-size: 16px;
  margin-left: 142px;
  margin-right: 25px;
  margin-bottom: 8px;
  line-height: 1.5;
  outline: none;
  border: none;
  resize: none;
  font-family: ${theme.fonts.primaryFontSemiBold};
  background-color: ${COLORS.PRIMARY_WHITE};
  @media ${theme.breakpoints.sm_down} {
    margin-left: 30px;
    font-size: 14px;
  }
`;

const AudioBar = styled.span`
  width: 75%;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueConfidence = styled.span`
  width: 48px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-right: 10px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;
