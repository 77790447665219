import React, { Fragment, useState, useEffect, useRef } from "react";
import ActionBar from "../../common/ActionBar";
import { PageWrapper, StyledLabel } from "./styles";
import LifeCycle from "../../common/LifeCycle/LifeCycle.js";
import CaseDetailsCard from "../../common/CaseDetails";
import CaseManager from "./common/CaseManager";
import BigBlueButton from "./common/BigBlueButton";
import MeetingAccess from "../CaseDetails/common/MeetingControl";
import RecordingControl from "../CaseDetails/common/RecordingControl";
import _ from "lodash";
import { navigate } from "@reach/router";
import CaseService from "../../../services/CaseService";
import useLoader from "../../../hooks/useLoader";
import {
  RESPONDANT_ONBOARDED,
  CLAIMENT_STR,
  BOTH_PAID_NEGOTIATION,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  CLAIMENT_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  AWAITING_RESPONDANT_QUIT,
  LIFE_CYCLE,
  CLAIMANT_LIFE_CYCLE,
} from "../../../helpers/constants";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import NegotiationState from "./NegotiationState";
import DrawerTable from "../../internal/DrawerTable";
import AssignCaseManager from "./common/AssignCaseManagerModal";
import MediationState from "./MediationState";
import labels from "../../../helpers/labels.json";
import queryString from "query-string";
import CaseCTA from "./common/CaseCTA";
import AlertDialog from "../../common/Alert";
import theme from "../../../assets/theme";
import TranscriptionControl from "./common/TranscriptionControl.js";
import { PrimaryCTAButton } from "../../common/Buttons";
import { CaseManagerCardData } from "./styles";
import { HyperLink } from "../../../styles/component/style";
// import TranscriptionSettings from "../../internal/TranscriptionSettings";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import SettingModal from "../TranscriptionSettings";
import ExtraFieldsDrawer from "../../internal/DrawerTable/ExtraFieldsDrawer.js";
import RTMP from "./common/RTMP.js";

export default function CaseDetails({ id }) {
  const [state, setState] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState(); // This state is to display the bread crum
  const { setLoader } = useLoader(); // This Hook is to display the loader
  const [caseDetails, setCaseDetails] = useState({}); // This state holds the case detaisl
  const [caesLifeCycle, setCaseLifeCyle] = useState([]); // This state holds the case lifecycle
  const [isRespondent, setIsRespondent] = useState(false); // This state tells current logged in user is respondent or not
  const [ownerId, setOwnerId] = useState(); // this state holds the ownerId
  const [respondentId, setRespondentId] = useState(); // THis state holds the respondent Id
  const { enqueueSnackbar } = useSnackbar(); // This Hook is used to trigger the snackbar message
  const [negotiationRounds, setNegotiationRounds] = useState({}); // THis state holds the NegotiationRounds
  const [currentState, setCurrentState] = useState({}); // This state Holds the current state
  const [pendingRegistrationFee, setPendingRegistrationFee] = useState(false);
  const [openAssignCaseManager, setOpenAssignCaseManager] = useState(false);
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [comments, setComments] = useState([]); // This is list of comments
  const [partyList, setPartyList] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [meetingDetail, setMeetingDetail] = useState();
  const [refreshStatus, setRefreshStatus] = useState(true);
  const [modal, setModal] = useState(false);
  const [fields, setFields] = useState(false);
  const [streamModal, setStreamModal] = useState(false);
  const [streamData, setStreamData] = useState("");

  const MTRef = useRef();
  /**THis useEffect is Used to get the case information */

  useEffect(() => {
    async function getCaseDetails(id) {
      try {
        const meetingurl = await CaseService.GetMeetingcases(id);
        setMeetingDetail(meetingurl);
        return;
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    if (id && refreshStatus) {
      getCaseDetails(id);
      setRefreshStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refreshStatus]);

  useEffect(() => {
    /** Function to get the CaseDetails */
    async function getCaseById(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const res = await CaseService.getCase(id);
        if (res) {
          let case_response = { ...res };
          if (case_response.status !== "draft") {
            if (case_response.status === "pendingRegistrationFee") {
              setPendingRegistrationFee(true);
            }
            if (!case_response.respondentPartyId) {
              case_response.status = res?.status;
            } else if (
              case_response.respondentStatus === "pending" ||
              case_response.respondentStatus === "declined"
            ) {
              case_response.status = RESPONDANT_ONBOARDED;
            } else if (
              case_response?.status === NEGOTIATION_REACHED &&
              case_response?.subscriptionKind === "adhoc"
            ) {
              case_response.status = BOTH_PAID_NEGOTIATION;
            }
          }
          setCaseDetails(case_response);
          setBreadcrumbs([
            _.startCase(labels.cases),
            _.startCase(case_response?.resolutionKind),
            case_response.title,
          ]);
          if (case_response?.resolutionKind === "negotiation") {
            getCaseNegotiations(id);
          } else {
            getCaseMediation(id);
          }
          getparty(id);
          setCaseLifeCyle(
            JSON.parse(
              JSON.stringify(
                case_response?.resolutionKind === "negotiation"
                  ? CLAIMANT_LIFE_CYCLE
                  : LIFE_CYCLE,
              ),
            ),
          );
          setIsRespondent(case_response.agentRole !== CLAIMENT_STR);
          setOwnerId(case_response.claimantPartyId);
          setRespondentId(case_response.respondentPartyId);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
        navigate("/dashboard/cases");
      } finally {
        setLoader({ state: false });
        window.scrollTo(0, 0);
      }
    }

    /** Function to get the negotiation round Details */
    async function getCaseNegotiations(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        const case_response = await CaseService.negotiations(id);
        setNegotiationRounds(case_response);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }

    async function getparty(id) {
      let params = {
        page: 1,
        perPage: 1000,
      };
      const partyParam = `?${queryString.stringify(
        params,
      )}&sort=partyRole&sortDirection=asc`;
      try {
        const party_list_res = await CaseService.getPartyListByCaseId(
          id,
          partyParam,
        ); // This is for getting the party details based on the case
        if (party_list_res?.data) {
          setPartyList(party_list_res?.data);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }

    /** Function to get the negotiation round Details */
    async function getCaseMediation(id) {
      setLoader({ state: true, message: "Fetching case details..." });
      try {
        let params = {
          page: 1,
          perPage: 1000,
        };
        let stringParams = "";
        if (!_.isEmpty(params)) {
          stringParams = `?${queryString.stringify(params)}`;
        }
        const mediation_res = await CaseService.mediations(id, stringParams);
        if (mediation_res) {
          setComments(mediation_res?.data);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }

    if (id && refreshDetails) {
      getCaseById(id);
      setRefreshDetails(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDetails]);

  /** THis is useEffect is used to set the curretState based on the status **/
  useEffect(() => {
    if (caesLifeCycle.length && !_.isEmpty(caseDetails)) {
      if (caesLifeCycle.some((el) => el.key === caseDetails.status)) {
        setCurrentState(
          caesLifeCycle.find((el) => el.key === caseDetails.status),
        );
      } else if (!isRespondent) {
        if (caseDetails.respondentId) {
          setCurrentState(caesLifeCycle.find((el) => el.index === 2));
        } else {
          setCurrentState(caesLifeCycle.find((el) => el.index === 1));
        }
      } else if (caseDetails.status === "finalAward") {
        setCurrentState({
          label: "Final Award",
          key: "finalAward",
          index: 5,
          enableSelection: false,
          cta: [],
        });
      }
    }
  }, [caesLifeCycle, caseDetails, isRespondent]);

  const onBreadcrumbClick = (selected) => {
    switch (selected) {
      case _.startCase(labels.cases):
        navigate(`/dashboard/cases?caseType=${caseDetails?.resolutionKind}`);
        break;
      case _.startCase(caseDetails?.resolutionKind):
        navigate(`/dashboard/cases?caseType=${caseDetails?.resolutionKind}`);
        break;
      default:
        break;
    }
  };

  async function callReopenCase() {
    setLoader({ state: true, message: "Updating case details..." });
    try {
      const res = await CaseService.updateCaseReopen(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setOpen(false);
      setLoader({ state: false });
      setRefreshDetails(true);
    }
    setOpen(false);
  }

  const onbuttonClick = (type) => {
    setOpen(true);
    switch (type) {
      case "REOPEN_CASE":
        setDialogData({
          primaryBtnText: "Cancel",
          secondaryBtnText: "Proceed",
          clickSecondarybtn: () => callReopenCase(),
          clickPrimaryBtn: () => setOpen(false),
          onDialogClose: () => setOpen(false),
          desc: `Click proceed to Reopen your case, Click cancel to Cancel your action`,
          heading: "Do you really want to Reopen your case?",
          buttonType: type,
          descriptionTextStyle: {
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold,
          },
        });
        break;
      default:
        break;
    }
  };

  async function startTranscription(id) {
    setLoader({ state: true, message: "Start transcription..." });
    try {
      const res = await CaseService.startTranscription(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
      setTimeout(() => {
        setRefreshStatus(true);
      }, 30000);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setStreamModal(false);
    }
  }

  async function StopTranscription(id) {
    try {
      setLoader({ state: true, message: "Update Meeting Status..." });
      const StopTranscription = await CaseService.caseStopTranscription(id);
      if (StopTranscription?.message) {
        enqueueSnackbar(StopTranscription?.message, {
          variant: "success",
        });
      }
      setTimeout(() => {
        setRefreshStatus(true);
      }, 30000);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setOpen(false);
      setLoader({ state: false });
    }
  }

  const stopTranscriptionAlert = (id) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "No",
      secondaryBtnText: "Yes",
      clickSecondarybtn: () => StopTranscription(id),
      clickPrimaryBtn: () => setOpen(false),
      onDialogClose: () => setOpen(false),
      desc: (
        <>
          After you have stopped this live transcription session your transcript
          will be moved to the case documents section. Please ensure you have
          saved your transcription changes. <br />
          <br />
          <b style={{ fontSize: "18px" }}>
            Are you sure you want to stop the transcription ?
          </b>
        </>
      ),
      heading: `Stop Transcription`,
      descriptionTextStyle: {
        textAlign: "left",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  async function generateRTMPLink(meetingId) {
    try {
      setLoader({ state: true, message: "fetching rtmp..." });
      const response = await CaseService.generateRTMPLink(meetingId);
      if (response?.message) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setStreamData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setOpen(false);
      setLoader({ state: false });
      setStreamModal(true);
    }
  }

  return (
    <Fragment>
      <ActionBar
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={onBreadcrumbClick}
      />
      <PageWrapper>
        <div>
          <CaseDetailsCard
            {...{
              setState,
              partyList,
              setFields,
            }}
            caseDetails={caseDetails}
            pendingRegistrationFee={pendingRegistrationFee}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="flex wrap">
            <div className="mr25 mt25">
              <CaseManager
                disabled={
                  caseDetails?.resolutionKind !== "negotiation" &&
                  (caseDetails?.status === "draft" ||
                    caseDetails?.caseManager?.disabled)
                }
                caseManager={caseDetails?.caseManager}
                {...{ ...caseDetails?.caseManager }}
                showRemove={
                  currentState?.index < 4 && caseDetails?.caseManager?.id
                }
                addCaseManager={() => setOpenAssignCaseManager(true)}
                removeCaseManager={() => setOpenAssignCaseManager(true)}
              />
            </div>
            {caseDetails?.requestedMediators?.length ? (
              <>
                {caseDetails?.requestedMediators
                  ?.filter((el) => el?.id)
                  ?.map((mediator, index) => (
                    <div className="mr25 mt25">
                      <CaseManager
                        key={index}
                        caseManager={mediator}
                        title={
                          caseDetails?.resolutionKind === "mediation"
                            ? "Mediator"
                            : "Arbitrator"
                        }
                      />
                    </div>
                  ))}
              </>
            ) : null}

            {caseDetails?.resolutionKind !== "negotiation" &&
            caseDetails?.mediator ? (
              <div className="mr25 mt25">
                <BigBlueButton
                  id={caseDetails?.id}
                  caseDetails={caseDetails}
                  status={caseDetails?.status}
                  resolutionKind={caseDetails?.resolutionKind}
                  type={`Join ${_.capitalize(
                    caseDetails?.resolutionKind,
                  )} Session`}
                />
              </div>
            ) : (
              <>
                {caseDetails?.resolutionKind &&
                  caseDetails?.resolutionKind !== "negotiation" &&
                  !caseDetails?.mediator && (
                    <div className="mr25 mt25">
                      <BigBlueButton
                        id={caseDetails?.id}
                        caseDetails={caseDetails}
                        status={caseDetails?.status}
                        resolutionKind={caseDetails?.resolutionKind}
                        type={`Join ${_.capitalize(
                          caseDetails?.resolutionKind,
                        )} Session`}
                      />
                    </div>
                  )}
              </>
            )}
            {meetingDetail?.recordingStatus === "yes" &&
            meetingDetail?.transcriptionStatus === "yes" &&
            meetingDetail?.status === "open" ? (
              <div className="mr25 mt28">
                {caseDetails?.resolutionKind !== "negotiation" && (
                  <CaseManagerCardData>
                    <PrimaryCTAButton
                      style={{ width: "295px" }}
                      onClick={() =>
                        navigate(
                          meetingDetail?.crossExamination
                            ? `/dashboard/cases/${id}/cross-examination?caseType=${caseDetails?.resolutionKind}`
                            : `/dashboard/cases/${id}/livetranscription?caseType=${caseDetails?.resolutionKind}`,
                          {
                            state: meetingDetail?.crossExamination
                              ? meetingDetail
                              : caseDetails,
                          },
                        )
                      }
                    >
                      {meetingDetail?.crossExamination
                        ? "Cross Examination"
                        : "Live Transcription"}
                    </PrimaryCTAButton>
                  </CaseManagerCardData>
                )}
              </div>
            ) : (
              ""
            )}
            {meetingDetail?.recordingStatus === "yes" &&
            meetingDetail?.transcriptionStatus === "yes" &&
            meetingDetail?.status === "open" ? (
              <div className="mr25 mt25">
                {caseDetails?.resolutionKind !== "negotiation" &&
                  caseDetails?.status !== "draft" && (
                    <CaseManagerCardData>
                      {meetingDetail?.transcriptionRunningStatus ? (
                        <PrimaryCTAButton
                          style={{
                            backgroundColor: "#D04848",
                            border: "1px solid #D04848",
                          }}
                          onClick={() => stopTranscriptionAlert(id)}
                        >
                          {"Stop Transcription"}
                        </PrimaryCTAButton>
                      ) : (
                        <PrimaryCTAButton
                          onClick={() =>
                            meetingDetail?.isLocalTranscriptionServer === 4
                              ? generateRTMPLink(meetingDetail?.meetingId)
                              : startTranscription(id)
                          }
                        >
                          {"Start Transcription"}
                        </PrimaryCTAButton>
                      )}
                    </CaseManagerCardData>
                  )}
              </div>
            ) : null}
            {caseDetails?.resolutionKind &&
              caseDetails?.resolutionKind !== "negotiation" && (
                <div className="mr25 mt25">
                  <MeetingAccess
                    {...{
                      state,
                      setState,
                      id,
                      MTRef,
                      meetingDetail,
                      setRefreshStatus,
                      caseDetails,
                    }}
                  />
                  <RecordingControl
                    {...{
                      state,
                      setState,
                      id,
                      MTRef,
                      meetingDetail,
                      caseDetails,
                      setRefreshStatus,
                    }}
                  />
                </div>
              )}
            {caseDetails?.resolutionKind &&
              caseDetails?.status !== "draft" &&
              caseDetails?.resolutionKind !== "negotiation" && (
                <div className="mr25 mt25">
                  <TranscriptionControl
                    {...{
                      state,
                      setState,
                      id,
                      MTRef,
                      meetingDetail,
                      setMeetingDetail,
                      setRefreshStatus,
                    }}
                  />
                </div>
              )}
            <div className="mr25 mt32">
              {caseDetails?.resolutionKind !== "negotiation" &&
                caseDetails?.status !== "draft" && (
                  <HyperLink onClick={() => setModal(true)}>
                    {"Transcription Settings"}
                  </HyperLink>
                )}
            </div>
          </div>
        </div>
        {caseDetails?.status !== "draft" && (
          <>
            <div style={{ marginTop: 28 }}>
              <StyledLabel>
                Case {caseDetails?.resolutionKind} Lifecycle
              </StyledLabel>
              <LifeCycle
                items={caesLifeCycle}
                selectedIndex={currentState?.index}
                isMediation={caseDetails?.resolutionKind !== "negotiation"}
                resolutionKind={caseDetails?.resolutionKind}
                status={currentState?.text}
                currentStateKey={currentState?.key}
              />
            </div>
            <div style={{ marginTop: 28 }}>
              {caseDetails?.resolutionKind === "negotiation" ? (
                <NegotiationState
                  {...{
                    currentState,
                    negotiationRounds,
                    isRespondent,
                    ownerId,
                    respondentId,
                    caseDetails,
                  }}
                  setRefreshDetails={setRefreshDetails}
                  isActionsDisabled={
                    currentState?.key === NEGOTIATION_REACHED ||
                    currentState?.key === NEGOTIATION_QUIT_BY_CLAIMENT ||
                    currentState?.key === NEGOTIATION_QUIT_BY_RESPONDENT ||
                    currentState?.key === NEGOTIATION_QUIT_BY_CASEMANAGER ||
                    currentState?.key === CLAIMENT_PAID_NEGOTIATION ||
                    currentState?.key === RESPONDENT_PAID_NEGOTIATION ||
                    currentState?.key === BOTH_PAID_NEGOTIATION ||
                    currentState?.key === AWAITING_RESPONDANT_QUIT
                  }
                />
              ) : (
                <MediationState
                  {...{
                    currentState,
                    negotiationRounds,
                    isRespondent,
                    ownerId,
                    respondentId,
                    caseDetails,
                    comments,
                    setComments,
                    partyList,
                  }}
                />
              )}
            </div>
            {caseDetails?.reasonForClose ? (
              <QuitContainer
                style={{
                  marginTop: 30,
                }}
              >
                <div>
                  <QuitLabel>Reason for Closing</QuitLabel>
                </div>
                <QuitContents>{caseDetails?.reasonForClose}</QuitContents>
              </QuitContainer>
            ) : null}
            {caseDetails?.dateofClose ? (
              <QuitContainer
                style={{
                  marginTop: 30,
                }}
              >
                <div>
                  <QuitLabel>Date of Close</QuitLabel>
                </div>
                <QuitContents>{caseDetails?.dateofClose}</QuitContents>
              </QuitContainer>
            ) : null}
            <div style={{ marginTop: 28 }}>
              <CaseCTA
                cta={
                  caseDetails?.resolutionKind !== "negotiation"
                    ? currentState.cta
                    : currentState?.cta?.filter(
                        (cta) => cta.type !== "MEDIATION_SUCCESS",
                      )
                }
                resolutionKind={caseDetails?.resolutionKind}
                disableButton={false}
                onbuttonClick={(type) => onbuttonClick(type)}
              />
            </div>
          </>
        )}
      </PageWrapper>
      <div>
        <AlertDialog
          isOpen={open}
          {...{ ...dialogData }}
          descriptionTextStyle={{
            textAlign: "left",
            fontFamily: theme.fonts.primaryFontSemiBold,
          }}
        />
      </div>
      <ExtraFieldsDrawer {...{ fields, setFields, caseDetails, id }} />
      <DrawerTable
        {...{ state, setState, id, MTRef }}
        type={caseDetails?.resolutionKind}
        claimantId={caseDetails?.claimantParty?.ownerId}
        respondentId={caseDetails?.respondentParty?.ownerId}
      />
      <AssignCaseManager
        modal={openAssignCaseManager}
        setModal={setOpenAssignCaseManager}
        onAssignSuccess={() => {
          setRefreshDetails(true);
          setOpenAssignCaseManager(false);
        }}
        caseDetails={caseDetails}
      />
      {/* <TranscriptionSettings
        modal={settingsModal}
        setModal={setSettingsModal}
        id={id}
        {...{ meetingDetail, setMeetingDetail }}
        setRefreshStatus={setRefreshStatus}
      /> */}
      <SettingModal {...{ id, modal, setModal, setRefreshStatus }} />
      {streamModal && (
        <RTMP
          {...{ streamModal, setStreamModal, streamData, id }}
          handleStart={startTranscription}
        />
      )}
    </Fragment>
  );
}

export const QuitContainer = styled.div`
  padding: 18px;
  border-radius: 7px;
  background-color: ${COLORS.LIGHT_BLUE};
  border: solid 1px ${COLORS.COLOR_DARK};
  margin-bottom: 12px;
  max-width: 80%;
`;

export const QuitLabel = styled.div`
  color: ${COLORS.LOGOUT_RED};
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
`;

export const QuitContents = styled.div`
  font-size: 14px;
  word-break: break-word;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-top: 18px;
  & strong,
  & bold {
    font-family: ${theme.fonts.primaryFontExtraBold};
  }
`;
